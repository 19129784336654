<template>
  <el-table-draggable handle=".handle" :animate="300" @drop="handleDrop">
    <el-table :data="sliderItems" :show-header="false">
      <el-table-column width="50px">
        <template>
          <div class="handle">
            <v-icon name="drag_handle" size="2rem"></v-icon>
          </div>
        </template>
      </el-table-column>
      <el-table-column width="220px">
        <template slot-scope="{row}">
          <img class="img-thumb" :src="getPhotoUrl(row.photo)" alt="img">
        </template>
      </el-table-column>
      <el-table-column min-width="250px">
        <template slot-scope="{row}">
          <el-form size="mini" class="slider-item-manage-form">
            <el-form-item :label="$t('slider_manage.slider_item.title')">
              <el-input v-model="row.title" :maxLength="80"></el-input>
            </el-form-item>
            <el-form-item :label="$t('slider_manage.slider_item.description')">
              <el-input v-model="row.description" :maxLength="80"></el-input>
            </el-form-item>
            <el-form-item :label="$t('slider_manage.slider_item.linked_unit')">
              <el-select v-model="row.unitId" clearable>
                <el-option v-for="unit in unitList"
                           :key="unit.unitId"
                           :value="unit.unitId"
                           :label="unit.title"
                />
              </el-select>
            </el-form-item>
          </el-form>
          <div class="slider-item-manage-actions">
            <el-popconfirm
                @confirm="removeImage(row.photoId)"
                :confirm-button-text="$t('slider_manage.slider_item.popover_delete.btn_confirm')"
                :cancel-button-text="$t('slider_manage.slider_item.popover_delete.btn_cancel')"
                icon="el-icon-info"
                :title="$t('slider_manage.slider_item.popover_delete.confirm_text')"
                icon-color="red"
            >
              <template #reference>
                <v-button icon="delete" size="mini" type="danger" class="remove-btn">
                  {{ $t('slider_manage.slider_item.remove') }}
                </v-button>
              </template>
            </el-popconfirm>
          </div>
        </template>
      </el-table-column>
    </el-table>
  </el-table-draggable>
</template>

<script>
import ElTableDraggable from 'element-ui-el-table-draggable'
import VIcon from '../../../components/ui/v-icon/VIcon'
import {UPLOADS_FOLDER_URL} from '../../../shared/constants/backend'
import {fetchUnitActiveList} from '../../../client/unit-page/services/unit-sevice'
import VButton from '../../../components/ui/v-button/VButton'
import {deleteSliderImage} from '../services/slider-manage'

const MODEL_EVENT = 'input'
const MODEL_PROP = 'sliderItems'
export default {
  name: 'SliderManageOrder',
  components: {
    VButton,
    ElTableDraggable,
    VIcon
  },
  props: {
    sliderItems: {
      type: Array,
      required: true
    }
  },
  model: {
    event: MODEL_EVENT,
    prop: MODEL_PROP,
  },
  data() {
    return {
      unitList: []
    }
  },
  mounted() {
    this.fetchUnitList()
  },
  methods: {
    getPhotoUrl(photo) {
      return `${UPLOADS_FOLDER_URL}/${photo}`
    },
    handleDrop(val) {
      this.$emit('input', val.list)
    },
    async fetchUnitList() {
      const {units} = await fetchUnitActiveList().catch((error) => {
        this.$message({
          showClose: true,
          message: error?.data,
          type: 'error'
        })
      })
      this.unitList = units
    },
    removeImage(imageId) {
      deleteSliderImage(imageId)
          .then(() => {
            this.$emit('image-removed')
          })
          .catch(() => {
            this.$message({
              showClose: true,
              message: this.$t('slider_manage.remove_image_error'),
              type: 'error'
            })
          })
    }
  }
}
</script>

<style scoped>
  .handle:hover {
    cursor: grab;
  }
  .handle:active {
    cursor: grabbing;
  }
  .img-thumb {
    width: 200px;
    height: 150px;
    object-fit: cover;
    object-position: center;
    border-radius: 0.8rem;
  }
  .slider-item-manage-form .el-form-item {
    margin-bottom: 0;
  }
  .slider-item-manage-actions {
    text-align: right;
    margin-top: 1em;
  }
</style>
