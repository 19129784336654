<template>
  <div>
    <VButton
        @click="openDialog"
        icon="upload"
    >
      {{ $t('page_unit_gallery_manage.upload_modal.btn_open') }}
    </VButton>
    <el-dialog
        v-loading="isLoading"
        destroy-on-close
        custom-class="upload-dialog"
        width="420px"
        :visible.sync="showDialog"
    >
      <template #title>
        <h3 class="heading heading--dialog">
          {{ $t('page_unit_gallery_manage.upload_modal.title') }}
        </h3>
      </template>
      <template #default>
        <el-upload
            drag
            action=""
            :auto-upload="false"
            :on-remove="handleChange"
            :on-change="handleChange"
            :file-list="fileList"
            multiple
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">
            {{ $t('page_unit_gallery_manage.upload_modal.upload_text') }}
            <em>
              {{ $t('page_unit_gallery_manage.upload_modal.upload_text_highlighted') }}
            </em>
          </div>
        </el-upload>
      </template>
      <template #footer class="dialog-footer">
        <el-button @click="closeDialog">
          {{ $t('page_unit_gallery_manage.upload_modal.btn_cancel') }}
        </el-button>
        <slot name="action-btn" />
      </template>
    </el-dialog>
  </div>
</template>

<script>

import VButton from '../ui/v-button/VButton'
export default {
  name: 'ImageUploadModal',
  components: {VButton},
  props: {
    isLoading: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      fileList: [],
      showDialog: false,
    }
  },
  watch: {
    showDialog(show) {
      if (!show) {
        this.fileList = []
      }
    }
  },
  methods: {
    handleChange(_, fileList) {
      this.fileList = fileList
      this.$emit('input', this.fileList)
    },
    openDialog() {
      this.showDialog = true
    },
    closeDialog() {
      this.showDialog = false
    },
    handleClose() {
      this.fileList = []
    },
  },
  computed: {
    totalFileSize() {
      const reducer = (acc, currentItem) => {
        return acc + currentItem.size
      }
      const INIT_VALUE = 0
      return this.fileList.reduce(reducer, INIT_VALUE)
    }
  }
}
</script>

<style lang="scss">
.heading--dialog {
  font-weight: 500;
  font-size: 1.3em;
}
.upload-dialog {
  .el-dialog__body {
    padding-top: 0;
  }
}
.el-upload-dragger {
  width: auto;
}
.el-upload--text {
  width: 100%;
}
</style>

