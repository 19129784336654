<template>
  <el-row>
    <el-col :lg="{span: 14, offset: 5}">
      <el-card v-loading.body="isLoading">
        <template #header>
          <div>
            <h2 class="heading page-heading">
              {{ $t('slider_manage.title') }}
            </h2>
          </div>
        </template>
        <template #default>
          <slider-manage-upload @uploaded="fetchImages"/>
          <el-form class="slider-options-form">
            <el-form-item :label="$t('slider_manage.swap_delay')">
              <el-input v-model.number="swapDelay" type="number"></el-input>
            </el-form-item>
          </el-form>
          <slider-manage-order v-model="sliderItems" @image-removed="fetchImages"/>
          <div class="card-actions">
            <v-button type="primary" @click="updateContent">
              {{ $t('slider_manage.btn_save') }}
            </v-button>
          </div>
        </template>
      </el-card>
    </el-col>
  </el-row>
</template>

<script>
import SliderManageOrder from './slider-manage-order/SliderManageOrder'
import SliderManageUpload from './slider-manage-upload/SliderManageUpload'
import {fetchSliderImages, updateSliderContent} from './services/slider-manage'
import VButton from '../../components/ui/v-button/VButton'
export default {
  name: 'SliderManage',
  components: {
    VButton,
    SliderManageUpload,
    SliderManageOrder,
  },
  data() {
    return {
      isLoading: false,
      swapDelay: 3000,
      sliderItems: []
    }
  },
  mounted() {
    this.fetchImages()
  },
  methods: {
    updateContent() {
      this.isLoading = true
      updateSliderContent(this.sliderItems, this.swapDelay)
          .then(() => {
            this.$message({
              message: `${this.$t('slider_manage.slider_content_update_success')}`,
              type: 'success'
            })
          })
          .catch(() => {
            this.$message({
              message: `${this.$t('slider_content_update_error')}`,
              type: 'error'
            })
          })
          .finally(() => {
            this.isLoading = false
          })
    },
    fetchImages() {
      this.isLoading = true
      fetchSliderImages()
          .then((res) => {
            const {durationTime, photos} = res.data
            this.sliderItems = photos
            this.swapDelay = durationTime
          })
          .catch(() => {
            this.$message({
              message: `${this.$t('slider_manage.fetch_slider_images_error')}`,
              type: 'error'
            })
          })
          .finally(() => {
            this.isLoading = false
          })
    }
  }
}
</script>

<style scoped lang="scss">
.unit-actions {
  .btn-back {
    margin-right: 1em;
  }
}
.card-actions {
  margin-top: 1em;
}
.slider-options-form {
  margin-top: 1em;
}
</style>
