<template>
  <image-upload-modal v-model="images" ref="modal" :is-loading="isLoading">
    <template #action-btn>
      <v-button type="primary" @click="uploadImages">
        {{ $t('slider_manage.btn_add') }}
      </v-button>
    </template>
  </image-upload-modal>
</template>

<script>
import ImageUploadModal from '../../../components/image-upload-modal/ImageUploadModal'
import VButton from '../../../components/ui/v-button/VButton'
import {uploadSliderImages} from '../services/slider-manage'

export default {
  name: 'SliderManageUpload',
  components: {
    ImageUploadModal,
    VButton
  },
  data() {
    return {
      isLoading: false,
      images: []
    }
  },
  methods: {
    uploadImages() {
      this.isLoading = true
      uploadSliderImages(this.images)
          .then(() => {
            this.$emit('uploaded')
            this.$refs.modal.closeDialog()
          })
          .catch(() => {
            this.$message({
              message: `${this.$t('error')}`,
              type: 'error'
            })
          })
          .finally(() => {
            this.isLoading = false
          })
    }
  }
}
</script>
